import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, initializeFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Inicialize o app Firebase
const app = initializeApp(firebaseConfig);

// Inicialize a autenticação
export const auth = getAuth(app);

// Inicialize o Firestore com configuração de cache
const db = initializeFirestore(app, {
  cacheSizeBytes: 1048576, // Tamanho do cache em bytes (1 MB)
});

// Habilite a persistência offline
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.log('Persistência pode ser habilitada apenas em uma aba por vez.');
    } else if (err.code === 'unimplemented') {
      console.log('O navegador atual não suporta todos os recursos necessários para habilitar a persistência.');
    }
  });

export { db };
