import React, { Suspense, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import './scss/style.scss';
import { AuthProvider } from './AuthContext';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const TelaCliente = React.lazy(() => import('./views/empresa/telacliente/TelaCliente'));
const Pedidoscliente = React.lazy(() => import('./views/empresa/meuspedidos/MeusPedidos'));
const HomePage = React.lazy(() => import('./views/empresa/PaginaInicial/Inicial'));

//Páginas em Destaque
const Business = React.lazy(()=> import('./views/empresa/PaginaInicial/PaginaDestaques/PaginaBusiness'));
const Restaurants = React.lazy(()=> import('./views/empresa/PaginaInicial/PaginaDestaques/PaginaRestaurants'));
const Offers = React.lazy(()=> import('./views/empresa/PaginaInicial/PaginaDestaques/PaginaOffers'));
const Categories = React.lazy(()=> import('./views/empresa/PaginaInicial/PaginaDestaques/PaginaCategories'));

const App = () => {
  const [variavel, setVariavel] = useState(null);
  const handleVariavelChange = (novaVariavel) => {
    setVariavel(novaVariavel);
  };

  return (
    
      <HashRouter>
        <AuthProvider variavel={variavel} onVariavelChange={handleVariavelChange}>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              path="/telacliente/:variavel"
              element={<TelaCliente onVariavelChange={handleVariavelChange} />}
            />
            <Route
              path="/inicial"
              element={<HomePage/>}
            />
            <Route
              path="/business"
              element={<Business/>}
            />
            <Route
              path="/restaurants"
              element={<Restaurants/>}
            />
            <Route
              path="/offers"
              element={<Offers/>}
            />
            <Route
              path="/categories"
              element={<Categories/>}
            />
            <Route
              path="/meuspedidos/:variavel"
              element={<Pedidoscliente onVariavelChange={handleVariavelChange} />} />
            <Route
              path="/*"
              element={
                <PrivateRoute element={<DefaultLayout />} />
              }
            />
          </Routes>
        </Suspense>
        </AuthProvider>
      </HashRouter>
  );
};

export default App;